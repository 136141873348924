<script setup>
import axios from "axios";
import { ref } from "vue";
import { useRoute } from "vue-router";
import { getCompra } from "@/utils/endpoints"
import { useStore } from "vuex";

const route = useRoute();
let idCompra = route.params.id;
let tipoCompra = ref("");
let importe = ref("");
let url_pf = ref("");

const store = useStore();
store.commit("resetCarrito");

axios.get(getCompra(idCompra))
    .catch(err => console.log(err))
    .then(data => {
        tipoCompra.value = data.data.payment_type_id;
        importe.value = data.data.order_total;
        url_pf.value = data.data.url_cupon_pf;
    })

</script>

<template>
    <div class="p-4 h-full w-full flex flex-col items-center justify-start">
        <div class="text-green-600">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-40 w-40 sm:h-60 sm:w-60" viewBox="0 0 20 20"
                fill="currentColor">
                <path fill-rule="evenodd"
                    d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd" />
            </svg>
        </div>
        <div class="mt-6">

            <!-- Transferencia -->
            <div v-if="tipoCompra == 2">
                <span class="text-4xl font-bold">Pago pendiente por transferencia bancaria</span>

                <div class="my-4 text-xl">
                    <span class="font-bold">Importe:</span>
                    ${{ importe }}
                </div>

                <div class="mt-2 text-xl">
                    <span class="font-bold">• Datos bancarios:</span>
                    <div class="pl-2.5 flex flex-col">
                        <span>Banco Galicia</span>
                        <span>Cuenta corriente 10601-2 019/0 | Sucursal 019</span>
                        <span>CBU 0070019120000010601200 | Alias de CBU: molamaq</span>
                    </div>
                </div>

                <div class="mt-2 text-2xl">
                    <span class>• Envie su comprobante de transferencia a</span>
                    <a class="text-main hover:underline" href="mailto:ventas@molamaq.com.ar">ventas@molamaq.com.ar</a>
                </div>
            </div>

            <!-- Pago Fácil -->
            <div v-if="tipoCompra == 3">
                <div class="mt-2 text-4xl">
                    <span class="font-bold">Pago pendiente por Pago Fácil</span>
                </div>

                <div class="my-4 text-2xl">
                    <span class="font-bold">Importe:</span>
                    ${{ importe }}
                </div>

                <a class="text-main hover:underline cursor-pointer text-2xl" :href="url_pf" target="_blank">Click Aqui
                    para obtener cupón de Pago Fácil</a>
            </div>

            <!-- Mercadopago -->
            <div v-if="tipoCompra == 4">
                <div class="mt-2 text-4xl">
                    <span class="font-bold">Pago pendiente por Mercadopago</span>
                </div>

                <div class="my-4 text-2xl">
                    <span class="font-bold">Importe:</span>
                    ${{ importe }}
                </div>

                <a class="text-main hover:underline cursor-pointer text-2xl" :href="url_pf" target="_blank">Click Aqui
                    para obtener cupón de Mercadopago</a>
            </div>

            <!-- Pago con tarjeta o Mercadopago -->
            <div v-if="tipoCompra == 1">
                <div class="mt-2 text-xl">
                    <span class="font-bold">Pago exitoso</span>
                </div>

                <div class="mt-2 text-xl flex flex-col">
                    <span>• Recibirás un mail con los detalles de tu compra.</span>
                    <span>• Molamaq se pondrá en contacto para entregarte tu pedido.</span>
                </div>
            </div>
        </div>
    </div>
</template>